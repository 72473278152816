<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Pages <span>Download Files</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body pt-4">
                <div class="setting_wpr">
                    <div class="capsule_elm">
                        <h5>Display Download Files?</h5>
                        <label for="show_download_setting" class="switch_option capsule_btn border-0">
                            <input type="checkbox" id="show_download_setting" :true-value="1" :false-value="0" v-model="form.is_download" @change="handleSubmit()" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="upload_image mt-2" v-show="form.is_download == 1">
                        <div id="page-download-dropzone" class="dropzone">
                            <div class="dz-message" data-dz-message>
                                <div>
                                    <img src="@/assets/images/image2.png">
                                    <h4>Click to Upload</h4>
                                    <p>or drag and drop</p>
                                </div>
                            </div>
                            <div v-for="(item, i) in fileSizeValidation" :key="i">
                                <span class="text-danger" >{{item}}</span>
                            </div>
                        </div>
                        <ul class="download_list mt-5" v-if="downloads.length">
                            <template v-for="(download, d) of downloads" :key="d">
                                <li :class="{ 'has-error': filenameValidation && isDownloadEdited && selectedDownload.id == download.id }">
                                    <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                        <i class="far fa-file-excel mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                        <i class="far fa-file-image mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['pdf'].includes(download.ext)">
                                        <i class="far fa-file-pdf mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                        <i class="far fa-file-video mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                        <i class="far fa-file-word mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                        <i class="far fa-file-powerpoint mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                        <i class="far fa-file-audio mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <h5 v-else>
                                        <i class="far fa-file mr-1"></i>
                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                    </h5>
                                    <span class="download-action w-70">
                                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                            <i class="fa fa-check pointer" @click="handleDownloadRename()"></i>
                                        </span>
                                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                            <i class="fa fa-times pointer" @click="selectedDownload = {}; isDownloadEdited = false; filenameValidation = false;"></i>
                                        </span>
                                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-om-blue">
                                            <i class="far fa-edit pointer" @click="handleEditDownload(download)"></i>
                                        </span>
                                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-danger p-0">
                                            <i class="far fa-trash-alt pointer" @click="handleDownloadRemove(download.id)"></i>
                                        </span>
                                    </span>
                                </li>
                                <div class="w-100 text-danger download-error" v-if="filenameValidation && isDownloadEdited && selectedDownload.id == download.id">
                                    The file name field is required.
                                </div>
                            </template>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="modal_footer">
                <button :disabled="pageUpdateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
    import Dropzone from 'dropzone'
    import 'dropzone/dist/dropzone.css'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import Swal from 'sweetalert2'

    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Page Download File',

        data () {
            return {
                form: {
                    is_download: 0,
                },
                filenameValidation: false,
                isDownloadEdited: false,
                selectedDownload: {},
                downloads: [],
                fileSizeValidation: false,
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (modelValue) {
                const vm = this;

                if (modelValue) {
                    setTimeout(function () {
                        vm.initDropzone();
                    }, 1000);
                }

                vm.resetForm();
            },

            pageDownloads (downloads) {
                const vm = this;

                vm.downloads = JSON.parse(JSON.stringify(downloads));
            },
        },

        computed: mapState({
            pageUpdateLoader: state => state.pageModule.pageUpdateLoader,
            selectedPage: state => state.pageModule.selectedPage,
            pageDownloads: state => state.pageModule.downloads,
            authToken: state => state.authModule.authToken,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePage: 'pageModule/updatePage',
                getPageDownloads: 'pageModule/getPageDownloads',
                deleteDownload: 'pageModule/deleteDownload',
                renameDownloadFile: 'pageModule/renameDownloadFile',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
                vm.fileSizeValidation = false;
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    page_id: vm.selectedPage.id,
                    is_download: vm.selectedPage.is_download ? vm.selectedPage.is_download : 0,
                };

                vm.downloads = JSON.parse(JSON.stringify(vm.pageDownloads));
            },

            handleSubmit () {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                vm.updatePage(params);
            },

            initDropzone () {
                const vm = this;

                const hasDropzoneEl = document.getElementById(`page-download-dropzone`);

                if (hasDropzoneEl) {
                    if (vm.dropzone) {
                        vm.dropzone.destroy();
                    }

                    vm.dropzone = new Dropzone(`#page-download-dropzone`, {
                        url: process.env.VUE_APP_API_URL+`/api/v3/page/${vm.selectedPage.id}/upload-download`,
                        uploadMultiple: true,
                        paramName: 'files',
                        headers: {
                            'Authorization': 'Bearer '+vm.authToken.access_token,
                            'accessed-location-id': vm.authToken.accessed_location_id,
                            'company-admin-id': vm.authToken.company_admin_id,
                        },
                        success: function (file, resp) {
                            vm.getPageDownloads(vm.selectedPage.id).then(() => {
                                if (file.previewElement) {
                                    file.previewElement.parentNode.removeChild(file.previewElement);
                                    const el = document.getElementById(`#page-download-dropzone`);
                                    el.classList.remove('dz-started');
                                    el.classList.remove('dz-max-files-reached');
                                }
                            });
                        },
                        error: function (file, error) {
                           vm.fileSizeValidation = error.errors["files.0"];
                           console.log(vm.fileSizeValidation);

                        },
                    });
                }
            },

            handleEditDownload (download) {
                const vm = this;

                vm.selectedDownload = JSON.parse(JSON.stringify(download));
                vm.isDownloadEdited = true;
                vm.filenameValidation = false;
            },

            handleDownloadRemove (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this file', 'Delete')
                      option.preConfirm = function() { return vm.deleteDownload({ id, page_id: vm.selectedPage.id }); };

                Swal.fire(option);
            },

            handleDownloadRename () {
                const vm = this;

                if (vm.selectedDownload.filename && vm.selectedDownload.filename.trim()) {
                    vm.filenameValidation = false;

                    vm.renameDownloadFile(vm.selectedDownload).then((result) => {
                        if (result) {
                            const index = vm.downloads.findIndex((download) => download.id == vm.selectedDownload.id);

                            vm.downloads[index].filename = vm.selectedDownload.filename;
                            vm.selectedDownload = {};
                            vm.isDownloadEdited = false;
                        }
                    });
                } else {
                    vm.filenameValidation = true;
                    Toastr.error('Please enter the file name to rename!');
                }
            },
        },
    }
</script>

<style scoped>
    :deep(.page-media-headline-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.page-media-headline-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }

    .w-70 {
        width: 70px !important;
    }

    .download-action span {
        border-left: 0px !important;
        height: 100% !important;
    }

    .download-action span +  span {
        margin-right: 10px;
    }
    .download_list{
        background: transparent;
        padding: 15px 0 0 0;
        display: flex;
        flex-direction: column;
        gap: 0;
        border: 0;
        border-radius: 0;
    }
    .download_list li {
        box-shadow: none;
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
        min-height: 40px;
    }

    .download_list li img {
        width: 40px;
        height: auto;
    }

    .download_list li h5 {
        padding: 0 10px;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .download_list li span {
        margin-left: auto;
        font-size: 12px;
        width: 20px;
        height: auto;
        border-left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .download_list h5 {
        width: 100%;
    }

    .download-editor {
        width: 100%;
        height: 100%;
        background: transparent;
        padding-left: 5px;
        font-size: 13px;
        font-weight: 400;
    }

    .download-error {
        margin-bottom: 10px;
        margin-top: -10px;
        text-align: left;
        font-size: 14px;
    }

    li.has-error {
        border-color: #eb1414;
    }

    .dropzone {
        border: 1.2px dashed #95bfff;
        background: #fafcfd;
        min-height: 150px;
    }
</style>
